export default [
  {
    path: '/yrupassport',
    name: 'yrupassport-callback',
    component: () => import('@/views/yrupassport/Callback.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/yrupassport/auth',
    name: 'yrupassport-auth',
    component: () => import('@/views/yrupassport/Auth.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/yrupassport/profile',
    name: 'yrupassport-profile',
    component: () => import('@/views/yrupassport/Profile.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
