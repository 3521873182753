export default [
  // master/product
  {
    path: '/master/product/list',
    name: 'master-product-list',
    component: () => import('@/views/master/product/Product.vue'),
    meta: {
      pageTitle: 'TitleMaster.Product',
      breadcrumb: [
        {
          text: 'TitleMaster.Product',
          to: '/master/product/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'product-master',
    },
  },
  // master/department
  {
    path: '/master/department/list',
    name: 'master-department-list',
    component: () => import('@/views/master/department/Department.vue'),
    meta: {
      pageTitle: 'TitleMaster.Department',
      breadcrumb: [
        {
          text: 'TitleMaster.Department',
          to: '/master/department/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'department-master',
    },
  },
  // master/fee_type
  {
    path: '/master/fee-type/list',
    name: 'master-fee-type-list',
    component: () => import('@/views/master/fee_type/FeeType.vue'),
    meta: {
      pageTitle: 'TitleMaster.FeeType',
      breadcrumb: [
        {
          text: 'TitleMaster.FeeType',
          to: '/master/fee-type/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'fee-type-master',
    },
  },
  // master/product_type
  {
    path: '/master/product-type/list',
    name: 'master-product-type-list',
    component: () => import('@/views/master/product_type/ProductType.vue'),
    meta: {
      pageTitle: 'TitleMaster.ProductType',
      breadcrumb: [
        {
          text: 'TitleMaster.ProductType',
          to: '/master/product-type/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'product-type-master',
    },
  },
  // master/product_category
  {
    path: '/master/product-category/list',
    name: 'master-product-category-list',
    component: () => import('@/views/master/product_category/ProductCategory.vue'),
    meta: {
      pageTitle: 'TitleMaster.ProductCategory',
      breadcrumb: [
        {
          text: 'TitleMaster.ProductCategory',
          to: '/master/product-category/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'product-category-master',
    },
  },
  // master/spec_template
  {
    path: '/master/spec-template/list',
    name: 'master-spec-template-list',
    component: () => import('@/views/master/spec_template/SpecTemplate.vue'),
    meta: {
      pageTitle: 'TitleMaster.SpecTemplate',
      breadcrumb: [
        {
          text: 'TitleMaster.SpecTemplate',
          to: '/master/spec-template/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'spec-template-master',
    },
  },
]
