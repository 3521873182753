import axios from '@axios'

const { createHash } = require('crypto')

export default {
  namespaced: true,
  state: {
    authorize: {
      client_id: '',
      response_type: '',
      redirect_uri: '',
      scope: '',
      state: '',
    },
    request_token: {
      grant_type: '',
      code: '',
      redirect_uri: '',
      client_id: '',
      client_secret: '',
    },
    token: {
      access_token: '',
      expires_in: '',
      refresh_token: '',
      token_type: '',
    },
    profile: {
      id: 0,
      avatar: '',
      username: '',
      prefix: '',
      prefix_edu: '',
      name: '',
      email: '',
      position_id: 0,
      position: '',
      department_id: 0,
      department: '',
      type: '',
      type_ref_id: 0,
      program_id: 0,
      program: '',
      class: '',
    },
    // profile: {
    //   id: 112971,
    //   avatar: 'https://passport.yru.ac.th/storage/uploads/images/image.jpg',
    //   username: 'user.test',
    //   prefix: 'นาย',
    //   prefix_edu: 'นาย',
    //   name: 'User Test',
    //   email: 'user.test@yru.ac.th',
    //   position_id: 37,
    //   position: 'นักวิชาการคอมพิวเตอร์',
    //   department_id: 28,
    //   department: 'สำนักวิทยบริการและเทคโนโลยีสารสนเทศ',
    //   type: 'staff',
    //   type_ref_id: 2427,
    //   program_id: null,
    //   program: null,
    //   class: null,
    // },
  },
  getters: {},
  mutations: {
    UPDATE_AUTHORIZE(state, val) {
      state.authorize = val
    },
    UPDATE_TOKEN(state, val) {
      state.token = val
    },
    UPDATE_PROFILE(state, val) {
      state.profile = val
    },
  },
  actions: {
    logoutUrl(ctx, params) {
      const {
        redirectUri, mode,
      } = params
      const logoutUrl = (mode === 'confirm') ? process.env.VUE_APP_YRU_PASSPORT_COMFIRM_LOGOUT_URL : process.env.VUE_APP_YRU_PASSPORT_LOGOUT_URL
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        try {
          const response = {}
          const data = {
            redirect_uri: `${window.location.origin}${process.env.BASE_URL}${redirectUri}`,
          }
          const url = new URL(logoutUrl)
          const urlParams = new URLSearchParams(url)
          urlParams.set('redirect_uri', data.redirect_uri)
          response.status = true
          response.data = data
          response.url = `${logoutUrl}?${urlParams.toString()}`
          resolve(response)
        } catch (error) {
          reject(error)
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    authorizeUrl(ctx, params) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        try {
          const response = {}
          const state = createHash('sha256').update((Math.random()) + (Date.now() * 1000)).digest('hex')
          const clientId = process.env.VUE_APP_YRU_PASSPORT_CLIENT_ID
          const responseType = 'code'
          const redirectUri = `${window.location.origin}${process.env.BASE_URL}yrupassport`
          const scope = 'openid profile email'

          const data = {
            client_id: clientId,
            response_type: responseType,
            redirect_uri: redirectUri,
            scope,
            state,
          }
          const url = new URL(process.env.VUE_APP_YRU_PASSPORT_AUTHORIZE_URL)
          const urlParams = new URLSearchParams(url)
          urlParams.set('client_id', data.client_id)
          urlParams.set('response_type', data.response_type)
          urlParams.set('redirect_uri', data.redirect_uri)
          urlParams.set('scope', data.scope)
          urlParams.set('state', data.state)
          response.status = true
          response.data = data
          response.url = `${process.env.VUE_APP_YRU_PASSPORT_AUTHORIZE_URL}?${urlParams.toString()}`
          resolve(response)
        } catch (error) {
          reject(error)
        }
      })
    },
    requestToken(ctx, params) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        const grantType = 'authorization_code'
        const redirectUri = `${window.location.origin}${process.env.BASE_URL}yrupassport`
        axios.post(process.env.VUE_APP_YRU_PASSPORT_TOKEN_URL, {
          grant_type: grantType,
          code: params.code,
          redirect_uri: redirectUri,
          client_id: process.env.VUE_APP_YRU_PASSPORT_CLIENT_ID,
          client_secret: process.env.VUE_APP_YRU_PASSPORT_CLIENT_SECRET,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    refreshToken(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_YRU_PASSPORT_TOKEN_URL, {
          grant_type: 'refresh_token',
          refresh_token: params.token.refresh_token,
          client_id: process.env.VUE_APP_YRU_PASSPORT_CLIENT_ID,
          client_secret: process.env.VUE_APP_YRU_PASSPORT_CLIENT_SECRET,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getProfile(ctx, token) {
      return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_YRU_PASSPORT_PROFILE_URL, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
