import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    lineOa: (localStorage.getItem(`${$themeConfig.app.session}-line-oa`) !== null) ? localStorage.getItem(`${$themeConfig.app.session}-line-oa`) : '',
  },
  getters: {},
  mutations: {
    UPDATE_LINE_OA(state, val) {
      localStorage.setItem(`${$themeConfig.app.session}-line-oa`, val)
      state.lineOa = val
    },
  },
  actions: {},
}
