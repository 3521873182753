export default [
  // auth-login
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/center/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // auth-register
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/center/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // auth-forgot-password
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/center/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // user-account
  {
    path: '/user-account',
    name: 'user-account',
    component: () => import('@/views/center/user-account/UserAccount.vue'),
    meta: {
      pageTitle: 'User Account',
      breadcrumb: [
        {
          text: 'User Account',
          active: true,
        },
      ],
    },
  },
  // user-list
  {
    path: '/user/list',
    name: 'user-list',
    component: () => import('@/views/center/user/user-list/UserList.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'User',
          to: '/user/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // user-edit
  {
    path: '/user/edit/:id',
    name: 'user-edit',
    component: () => import('@/views/center/user/user-edit/UserEdit.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'User',
          to: '/user/list',
          active: false,
        },
        {
          text: 'User List',
          to: '/user/list',
          active: false,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      navActiveLink: 'user-list',
    },
  },
  // user-view
  {
    path: '/user/view/:id',
    name: 'user-view',
    component: () => import('@/views/center/user/user-view/UserView.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'User',
          to: '/user/list',
          active: false,
        },
        {
          text: 'List',
          to: '/user/list',
          active: false,
        },
        {
          text: 'View',
          active: true,
        },
      ],
      navActiveLink: 'user-list',
    },
  },
  // user-add
  {
    path: '/user/add',
    name: 'user-add',
    component: () => import('@/views/center/user/user-add/UserAdd.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'User',
          to: '/user/list',
          active: false,
        },
        {
          text: 'List',
          to: '/user/list',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
      navActiveLink: 'user-list',
    },
  },
  // auth-verify-email
  {
    path: '/verifyemail/:id/:token',
    name: 'auth-verify-email',
    component: () => import('@/views/center/authentication/VerifyEmail.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  // auth-reset-password
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/center/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  // permission
  {
    path: '/permission',
    name: 'permission',
    component: () => import('@/views/center/permission/Permission.vue'),
    meta: {
      pageTitle: 'Permission',
      breadcrumb: [
        {
          text: 'Permission',
          to: '/permission',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'permission',
    },
  },
]
