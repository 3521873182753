export default [
  // lineapi/lineuser
  {
    path: '/lineapi/lineuser/list',
    name: 'lineapi-lineuser-list',
    component: () => import('@/views/lineapi/lineuser/LineUserList.vue'),
    meta: {
      pageTitle: 'Line User',
      breadcrumb: [
        {
          text: 'Line User',
          to: '/lineapi/lineuser/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'lineuser-yru',
    },
  },
  // lineapi/lineliff
  {
    path: '/lineapi/lineliff/list',
    name: 'lineapi-lineliff-list',
    component: () => import('@/views/lineapi/lineliff/LineLiffList.vue'),
    meta: {
      pageTitle: 'Line Liff',
      breadcrumb: [
        {
          text: 'Line Liff',
          to: '/lineapi/lineliff/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // lineapi/richmenu
  {
    path: '/lineapi/richmenu/list',
    name: 'lineapi-richmenu-list',
    component: () => import('@/views/lineapi/richmenu/RichMenuList.vue'),
    meta: {
      pageTitle: 'Rich Menu',
      breadcrumb: [
        {
          text: 'Rich Menu',
          to: '/lineapi/richmenu/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/lineapi/richmenutemplate/add',
    name: 'lineapi-richmenu-template-add',
    component: () => import('@/views/lineapi/richmenu/RichMenuTemplateAdd.vue'),
    meta: {
      pageTitle: 'Rich Menu Template',
      breadcrumb: [
        {
          text: 'Rich Menu',
          to: '/lineapi/richmenu/list',
          active: false,
        },
        {
          text: 'Rich Menu Template',
          to: '/lineapi/richmenutemplate/add',
          active: false,
        },
        {
          text: 'Add',
          active: true,
        },
      ],
      navActiveLink: 'lineapi-richmenu-list',
    },
  },
  {
    path: '/lineapi/richmenutemplate/edit/:id',
    name: 'lineapi-richmenu-template-edit',
    component: () => import('@/views/lineapi/richmenu/RichMenuTemplateEdit.vue'),
    meta: {
      pageTitle: 'Rich Menu Template',
      breadcrumb: [
        {
          text: 'Rich Menu',
          to: '/lineapi/richmenu/list',
          active: false,
        },
        {
          text: 'Rich Menu Template',
          active: true,
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
      navActiveLink: 'lineapi-richmenu-list',
    },
  },
  {
    path: '/lineapi/richmenu/link/:id',
    name: 'lineapi-richmenu-link',
    component: () => import('@/views/lineapi/richmenu/RichMenuLink.vue'),
    meta: {
      pageTitle: 'Rich Menu Link',
      breadcrumb: [
        {
          text: 'Rich Menu',
          to: '/lineapi/richmenu/list',
          active: false,
        },
        {
          text: 'List',
          to: '/lineapi/richmenu/list',
          active: false,
        },
        {
          text: 'Link',
          active: true,
        },
      ],
      navActiveLink: 'lineapi-richmenu-list',
    },
  },
  // lineapi/media
  {
    path: '/lineapi/media',
    name: 'lineapi-media',
    component: () => import('@/views/lineapi/media/Media.vue'),
    meta: {
      contentClass: 'media-yru',
      pageTitle: 'Media',
      breadcrumb: [
        {
          text: 'Media',
          to: '/lineapi/media',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // lineapi/chatmessage
  {
    path: '/lineapi/chatmessage/list',
    name: 'lineapi-chatmessage-list',
    component: () => import('@/views/lineapi/chatmessage/ChatmessageList.vue'),
    meta: {
      pageTitle: 'Chatmessage',
      breadcrumb: [
        {
          text: 'Chatmessage',
          to: '/lineapi/chatmessage/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'chatmessage-yru',
    },
  },
  // lineapi/replymessage
  {
    path: '/lineapi/replymessage/list',
    name: 'lineapi-replymessage-list',
    component: () => import('@/views/lineapi/replymessage/ReplymessageList.vue'),
    meta: {
      pageTitle: 'Replymessage',
      breadcrumb: [
        {
          text: 'Replymessage',
          to: '/lineapi/replymessage/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'replymessage-yru',
    },
  },
  // lineapi/replymessage
  {
    path: '/lineapi/replymessage',
    name: 'lineapi-replymessage',
    component: () => import('@/views/lineapi/replymessage/Replymessage.vue'),
    meta: {
      pageTitle: 'Replymessage',
      breadcrumb: [
        {
          text: 'Replymessage',
          to: '/lineapi/replymessage',
          active: true,
        },
      ],
    },
  },
  // lineapi/dialogue
  {
    path: '/lineapi/dialogue/list',
    name: 'lineapi-dialogue-list',
    component: () => import('@/views/lineapi/dialogue/DialogueList.vue'),
    meta: {
      pageTitle: 'Dialogue',
      breadcrumb: [
        {
          text: 'Dialogue',
          to: '/lineapi/dialogue/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'dialogue-yru',
    },
  },
  // lineapi/audience
  {
    path: '/lineapi/audience/list',
    name: 'lineapi-audience-list',
    component: () => import('@/views/lineapi/audience/AudienceList.vue'),
    meta: {
      pageTitle: 'Audience',
      breadcrumb: [
        {
          text: 'Audience',
          to: '/lineapi/audience/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'audience-yru',
    },
  },
  // lineapi/multicast
  {
    path: '/lineapi/multicast',
    name: 'lineapi-multicast',
    component: () => import('@/views/lineapi/multicast/Multicast.vue'),
    meta: {
      pageTitle: 'Multicast',
      breadcrumb: [
        {
          text: 'Multicast',
          to: '/lineapi/multicast',
          active: true,
        },
      ],
      contentClass: 'multicast-yru',
    },
  },
  // lineapi/sharetarget
  {
    path: '/lineapi/sharetarget/list',
    name: 'lineapi-sharetarget-list',
    component: () => import('@/views/lineapi/sharetarget/ShareTargetList.vue'),
    meta: {
      pageTitle: 'Share Target',
      breadcrumb: [
        {
          text: 'Share Target',
          to: '/lineapi/sharetarget/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'sharetarget',
    },
  },
  // lineapi/sharetarget_transaction
  {
    path: '/lineapi/sharetarget_transaction/list',
    name: 'lineapi-sharetarget-transaction-list',
    component: () => import('@/views/lineapi/sharetarget_transaction/ShareTargetTransactionList.vue'),
    meta: {
      pageTitle: 'Share Target Transaction',
      breadcrumb: [
        {
          text: 'Share Target Transaction',
          to: '/lineapi/sharetarget_transaction/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'sharetarget-transaction',
    },
  },
  // lineapi/formliff/list
  {
    path: '/lineapi/formliff/list',
    name: 'lineapi-formliff-list',
    component: () => import('@/views/lineapi/formliff/FormLiffList.vue'),
    meta: {
      pageTitle: 'Form Liff',
      breadcrumb: [
        {
          text: 'Form Liff',
          to: '/lineapi/formliff/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // // lineapi/formliff
  // {
  //   path: '/lineapi/formliff/design',
  //   name: 'lineapi-formliff-design',
  //   component: () => import('@/views/lineapi/formliff/FormLiffDesign.vue'),
  //   meta: {
  //     pageTitle: 'Form Liff',
  //     breadcrumb: [
  //       {
  //         text: 'Form Liff',
  //         to: '/lineapi/formliff/design',
  //         active: false,
  //       },
  //       {
  //         text: 'Design',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

]
