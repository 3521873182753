export default [
  // booking/payment_type
  {
    path: '/booking/payment-type/list',
    name: 'booking-payment-type-list',
    component: () => import('@/views/booking/payment_type/PaymentType.vue'),
    meta: {
      pageTitle: 'TitleBooking.PaymentType',
      breadcrumb: [
        {
          text: 'TitleBooking.PaymentType',
          to: '/booking/payment-type/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'payment-type-booking',
    },
  },
  // booking/role
  {
    path: '/booking/role/list',
    name: 'booking-role-list',
    component: () => import('@/views/booking/role/Role.vue'),
    meta: {
      pageTitle: 'TitleBooking.Role',
      breadcrumb: [
        {
          text: 'TitleBooking.Role',
          to: '/booking/role/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'role-booking',
    },
  },
  // booking/email_template
  {
    path: '/booking/email_template/list',
    name: 'booking-email-template-list',
    component: () => import('@/views/booking/email_template/EmailTemplate.vue'),
    meta: {
      pageTitle: 'TitleBooking.EmailTemplate',
      breadcrumb: [
        {
          text: 'TitleBooking.EmailTemplate',
          to: '/booking/email_template/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // booking/notification_template
  {
    path: '/booking/notification_template/list',
    name: 'booking-notification-template-list',
    component: () => import('@/views/booking/notification_template/NotificationTemplate.vue'),
    meta: {
      pageTitle: 'TitleBooking.NotificationTemplate',
      breadcrumb: [
        {
          text: 'TitleBooking.NotificationTemplate',
          to: '/booking/notification_template/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // booking/line_notify_template
  {
    path: '/booking/line_notify_template/list',
    name: 'booking-line-notify-template-list',
    component: () => import('@/views/booking/line_notify_template/LineNotifyTemplate.vue'),
    meta: {
      pageTitle: 'TitleBooking.LineNotifyTemplate',
      breadcrumb: [
        {
          text: 'TitleBooking.LineNotifyTemplate',
          to: '/booking/line_notify_template/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // booking/booking
  {
    path: '/booking/booking/list',
    name: 'booking-booking-list',
    component: () => import('@/views/booking/booking/BookingList.vue'),
    meta: {
      pageTitle: 'TitleBooking.Booking',
      breadcrumb: [
        {
          text: 'TitleBooking.Booking',
          to: '/booking/booking/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'booking-booking',
    },
  },
  // booking/renting
  {
    path: '/booking/renting/list',
    name: 'booking-renting-list',
    component: () => import('@/views/booking/renting/RentingList.vue'),
    meta: {
      pageTitle: 'TitleBooking.Renting',
      breadcrumb: [
        {
          text: 'TitleBooking.Renting',
          to: '/booking/renting/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'booking-renting',
    },
  },
  // booking/calendar
  {
    path: '/booking/calendar',
    name: 'booking-calendar',
    component: () => import('@/views/booking/calendar/Calendar.vue'),
    meta: {
      // pageTitle: 'TitleBooking.Calendar',
      // breadcrumb: [
      //   {
      //     text: 'TitleBooking.Calendar',
      //     to: '/booking/calendar',
      //     active: false,
      //   },
      //   {
      //     text: 'List',
      //     active: true,
      //   },
      // ],
      contentClass: 'booking-calendar',
    },
  },
  // booking/chat
  {
    path: '/booking/chat',
    name: 'booking-chat',
    component: () => import('@/views/booking/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  // booking/payment
  {
    path: '/booking/payment/list',
    name: 'booking-payment-list',
    component: () => import('@/views/booking/payment/PaymentList.vue'),
    meta: {
      pageTitle: 'TitleBooking.Payment',
      breadcrumb: [
        {
          text: 'TitleBooking.Payment',
          to: '/booking/payment/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'payment-booking',
    },
  },
  // booking/contract
  {
    path: '/booking/contract/list',
    name: 'booking-contract-list',
    component: () => import('@/views/booking/contract/ContractList.vue'),
    meta: {
      pageTitle: 'TitleBooking.Contract',
      breadcrumb: [
        {
          text: 'TitleBooking.Contract',
          to: '/booking/contract/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'booking-contract',
    },
  },
  // booking/contract_type
  {
    path: '/booking/contract-type/list',
    name: 'booking-contract-type-list',
    component: () => import('@/views/booking/contract_type/ContractType.vue'),
    meta: {
      pageTitle: 'TitleBooking.ContractType',
      breadcrumb: [
        {
          text: 'TitleBooking.ContractType',
          to: '/booking/contract-type/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'contract-type-booking',
    },
  },
  // booking/satisfaction
  {
    path: '/booking/satisfaction/list',
    name: 'booking-satisfaction-list',
    component: () => import('@/views/booking/satisfaction/SatisfactionList.vue'),
    meta: {
      pageTitle: 'TitleBooking.Satisfaction',
      breadcrumb: [
        {
          text: 'TitleBooking.Satisfaction',
          to: '/booking/satisfaction/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'booking-satisfaction',
    },
  },
  // booking/line_notify
  {
    path: '/booking/line_notify/list',
    name: 'booking-line-notify-list',
    component: () => import('@/views/booking/line_notify/LineNotify.vue'),
    meta: {
      pageTitle: 'TitleBooking.LineNotify',
      breadcrumb: [
        {
          text: 'TitleBooking.LineNotify',
          to: '/booking/line_notify/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  // booking/satisfaction_transaction
  {
    path: '/booking/satisfaction_transaction/list',
    name: 'booking-satisfaction-transaction-list',
    component: () => import('@/views/booking/satisfaction_transaction/SatisfactionTransactionList.vue'),
    meta: {
      pageTitle: 'TitleBooking.SatisfactionTransaction',
      breadcrumb: [
        {
          text: 'TitleBooking.SatisfactionTransaction',
          to: '/booking/satisfaction_transaction/list',
          active: false,
        },
        {
          text: 'List',
          active: true,
        },
      ],
      contentClass: 'booking-satisfaction-transaction',
    },
  },
]
