export default [
  // report/booking
  {
    path: '/report/booking',
    name: 'report-booking',
    component: () => import('@/views/report/booking/ReportBooking.vue'),
    meta: {
      pageTitle: 'TitleReport.Booking',
      breadcrumb: [
        {
          text: 'TitleReport.Booking',
          to: '/report/booking',
          active: false,
        },
        {
          text: 'Report',
          active: true,
        },
      ],
      contentClass: 'report-booking',
    },
  },
  // report/renting
  {
    path: '/report/renting',
    name: 'report-renting',
    component: () => import('@/views/report/renting/ReportRenting.vue'),
    meta: {
      pageTitle: 'TitleReport.Renting',
      breadcrumb: [
        {
          text: 'TitleReport.Renting',
          to: '/report/renting',
          active: false,
        },
        {
          text: 'Report',
          active: true,
        },
      ],
      contentClass: 'report-renting',
    },
  },
  // report/payment
  {
    path: '/report/payment',
    name: 'report-payment',
    component: () => import('@/views/report/payment/ReportPayment.vue'),
    meta: {
      pageTitle: 'TitleReport.Payment',
      breadcrumb: [
        {
          text: 'TitleReport.Payment',
          to: '/report/payment',
          active: false,
        },
        {
          text: 'Report',
          active: true,
        },
      ],
      contentClass: 'report-payment',
    },
  },
]
